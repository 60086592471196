export const STATUSES = [
  {
    key: 'active',
    name: 'Activo'
  },
  {
    key: 'inactive',
    name: 'Inactivo'
  }
]

export const PRODUCT_CONDITIONS = [
  {
    key: 'new',
    name: 'Nuevo'
  },
  {
    key: 'used',
    name: 'Usado'
  }
]
