<template>
  <div>
    <v-row
      v-for="(shipmentType, index) in deliveryZone.shipment_types"
      :key="'shipment_type_form_row_' + index"
      no-gutters>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 11">
        <div class="wrapper">
          <div v-if="$vuetify.breakpoint.smAndUp" class="row-number">{{ index + 1 }}.</div>
          <div class="wrapper-content">
            <v-row no-gutters>
              <v-col cols="1" v-if="$vuetify.breakpoint.xsOnly">
                <div class="row-number">{{ index+1 }}.</div>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 11 : 4">
                <v-select
                  class="form-input"
                  v-model="shipmentType.delivery_type"
                  :items="deliveryTypes"
                  item-text="name"
                  item-value="key"
                  label="Método de envió"
                  :rules="[ isRequired ]"
                  outlined
                  dense
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4" v-if="shipmentType.delivery_type !== collectInStore">
                <v-select
                  class="form-input"
                  v-model="shipmentType.use_carrier_price"
                  :items="options"
                  item-text="name"
                  item-value="key"
                  label="Usar costo del proveedor"
                  :rules="[ isRequiredBoolean ]"
                  outlined
                  dense
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4" v-if="shipmentType.use_carrier_price === false && shipmentType.delivery_type !== collectInStore">
                <v-text-field
                  class="form-input"
                  v-model.number="shipmentType.cost"
                  label="Costo de envió"
                  :rules="[ isRequiredNumeric, isNumeric ]"
                  outlined
                  dense
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4" v-if="shipmentType.delivery_type === collectInStore">
                <v-text-field
                  class="form-input"
                  v-model.number="shipmentType.delivery_days"
                  label="Dias de entrega"
                  :rules="[ isRequiredNumeric, isNumeric ]"
                  outlined
                  dense
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4" v-if="shipmentType.delivery_type === cashOnDelivery">
                <v-select
                  class="form-input"
                  v-model="shipmentType.assume_cost_of_collection"
                  :items="options"
                  item-text="name"
                  item-value="key"
                  label="Asumir costo de recaudación"
                  :rules="[ isRequiredBoolean ]"
                  outlined
                  dense
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4" v-if="shipmentType.delivery_type !== collectInStore">
                <v-text-field
                  class="form-input"
                  v-model.number="shipmentType.free_delivery_threshold"
                  label="Valor para envío gratis"
                  :rules="[ isRequiredNumeric, isNumeric ]"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col :class="['delete-col', { 'text-center': $vuetify.breakpoint.smAndUp, 'text-right': $vuetify.breakpoint.xsOnly }]" :cols="$vuetify.breakpoint.xsOnly ? 12 : 1" v-if="index > 0">
        <v-btn icon @click="deleteShipmentType(index)">
          <v-icon light>delete_outline</v-icon>
        </v-btn>
      </v-col>
      <v-col class="form-divider-container" cols="12" v-if="$vuetify.breakpoint.xsOnly">
        <hr class="form-divider">
      </v-col>
    </v-row>
    <div class="form-add-row">
      <span class="add-row-text" @click="addShipmentType">+ agregar</span>
    </div>
  </div>
</template>
<script>
import { isRequired, isNumeric, isRequiredNumeric, isRequiredBoolean } from '@/shared/validations'
import { DELIVERY_TYPES, DELIVERY_TYPE_COLLECT_IN_STORE, DELIVERY_TYPE_CASH_ON_DELIVERY } from '@/shared/deliveryTypes'

export default {
  props: {
    deliveryZone: {
      type: Object,
      default: function () {
        return {
          shipment_types: [
            {
              delivery_type: null,
              cost: null,
              delivery_days: null,
              use_carrier_price: null,
              free_delivery_threshold: null,
              assume_cost_of_collection: null
            }
          ]
        }
      }
    }
  },
  data () {
    return {
      deliveryTypes: DELIVERY_TYPES,
      collectInStore: DELIVERY_TYPE_COLLECT_IN_STORE,
      cashOnDelivery: DELIVERY_TYPE_CASH_ON_DELIVERY,
      options: [
        {
          key: false,
          name: 'No'
        },
        {
          key: true,
          name: 'Si'
        }
      ]
    }
  },
  methods: {
    isRequired,
    isNumeric,
    isRequiredNumeric,
    isRequiredBoolean,
    addShipmentType () {
      this.deliveryZone.shipment_types.push({
        delivery_type: null,
        cost: null,
        delivery_days: null,
        use_carrier_price: null,
        free_delivery_threshold: null,
        assume_cost_of_collection: null
      })
      this.$forceUpdate()
    },
    deleteShipmentType (index) {
      this.deliveryZone.shipment_types.splice(index, 1)
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped>
.wrapper {
  display: flex;
  height: 100%;
}
.wrapper-content {
  flex-grow: 1;
  max-width: 94%;
}
.row-number {
  font-size: 1.563rem;
  font-weight: 500;
  margin-top: 0.125rem;
  margin-right: 1rem;
}
.form-input {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.delete-col {
  margin-top: 0.125rem;
}
.form-divider-container {
  padding-top: 0.25rem !important;
  padding-bottom: 1.75rem !important;
}
.form-divider {
  left: 0;
  right: 0;
  background-color: #DDDDDD;
  border: none;
  height: 0.063rem;
}
.form-add-row {
  font-size: 0.875rem;
  color: var(--app-button-bg-color);
  text-align: left;
}
.add-row-text {
  cursor: pointer;
  text-transform: lowercase;
}
@media only screen and (max-width: 37.5rem) {
  .wrapper-content {
    max-width: 100%;
  }
  .delete-col {
    margin-top: -0.75rem;
  }
  .row-number{
    margin-right: 0;
  }
  .form-input {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .form-divider {
    position: absolute;
  }
}
@media only screen and (max-width: 20.625rem) {
  .row-number{
    font-size: 1.25rem;
    margin-top: 0.25rem;
    margin-right: 0;
  }
}
</style>
