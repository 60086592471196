export const DELIVERY_ZONE_TABS = [
  {
    name: 'delivery-zone-vital-info',
    title: 'Empecemos identificando la información vital de la zona de envió y las ciudades de entrega',
    description: 'Ingresa la información vital de la zona de envió',
    errorMessage: 'Por favor seleccione las ciudades de entrega para continuar',
    useLoading: false
  },
  {
    name: 'delivery-zone-shipment-type',
    title: 'Ingresa los métodos de envió para la zona de entrega',
    description: 'Agrega los métodos de envió disponibles para la zona de entrega, puedes agregar varios',
    useLoading: true
  }
]
