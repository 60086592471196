<template>
  <form-container class="form-container"
    :title="title"
    :description="description"
    :buttonText="buttonText"
    :useLoading="useLoading"
    @submit="next" >
    <template v-slot:header class="form-content">
      <v-row no-gutters>
        <v-col class="text-left" v-if="tabIndex > 0">
          <div class="back-container" @click="back">
            <v-icon class="back-icon">mdi-keyboard-backspace</v-icon>
            <span class="back-text">&nbsp; Atrás</span>
          </div>
        </v-col>
        <v-col class="text-right">
          <span class="current-step">Paso {{ currentStep }}</span>
          <span class="total-step">de {{ totalStep }}</span>
        </v-col>
      </v-row>
      <div></div>
    </template>
    <template v-slot:content class="form-content">
      <div v-if="formComponent">
        <component :is="formComponent" :deliveryZone="deliveryZone" />
      </div>
    </template>
  </form-container>
</template>
<script>
import { isRequired } from '@/shared/validations'
import { mapState, mapActions } from 'vuex'
import FormContainer from '@/components/Form/FormContainer'
import DeliveryVitalInfoFrom from '@/components/DeliveryZone/DeliveryVitalInfoFrom'
import ShipmentTypeForm from '@/components/DeliveryZone/ShipmentTypeForm'
import { DELIVERY_ZONE_TABS } from '@/forms/delivery-zone'

export default {
  components: {
    FormContainer,
    DeliveryVitalInfoFrom,
    ShipmentTypeForm
  },
  props: {
    deliveryZone: {
      type: Object,
      default: function () {
        return {
          name: null,
          cities: [],
          shipment_types: [
            {
              delivery_type: null,
              cost: null,
              delivery_days: null
            }
          ],
          status: 'active'
        }
      }
    }
  },
  computed: {
    ...mapState('delivery', ['regions', 'cities']),
    description () {
      return this.tabsConfig[this.tabIndex].description
    },
    title () {
      return this.tabsConfig[this.tabIndex].title
    },
    currentStep () {
      return this.tabIndex + 1
    },
    totalStep () {
      return DELIVERY_ZONE_TABS.length
    },
    buttonText () {
      return this.tabIndex === DELIVERY_ZONE_TABS.length - 1 ? 'Guardar' : 'Siguiente'
    },
    useLoading () {
      return this.tabsConfig[this.tabIndex].useLoading
    }
  },
  data () {
    return {
      loadingDepartments: true,
      tabsConfig: DELIVERY_ZONE_TABS,
      tabIndex: 0,
      region: null,
      search: '',
      formContents: {
        'delivery-zone-vital-info': DeliveryVitalInfoFrom,
        'delivery-zone-shipment-type': ShipmentTypeForm
      },
      formComponent: null
    }
  },
  methods: {
    isRequired,
    ...mapActions('notification', ['showNotification']),
    setFormContent () {
      const formName = this.tabsConfig[this.tabIndex].name
      this.formComponent = this.formContents[formName]
    },
    validate () {
      const formName = this.tabsConfig[this.tabIndex].name
      const currentComponent = this.formContents[formName]
      if (!currentComponent) {
        return true
      }

      switch (formName) {
        case 'delivery-zone-region':
          return !!this.deliveryZone.cities && this.deliveryZone.cities.length !== 0
        default:
          return true
      }
    },
    back () {
      if (this.tabIndex === 0) {
        return
      }

      this.tabIndex--
      this.setFormContent()
    },
    next () {
      if (!this.validate()) {
        this.showNotification({ message: DELIVERY_ZONE_TABS[this.tabIndex].errorMessage, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      if (this.tabIndex === this.totalStep - 1) {
        this.$emit('submit', this.deliveryZone)
        return
      }

      this.tabIndex++
      this.setFormContent()
    }
  },
  mounted () {
    this.setFormContent()
  }
}
</script>
<style scoped>
.back-container {
  color: var(--app-button-bg-color);
  cursor: pointer;
}
.back-icon {
  color: var(--app-button-bg-color);
  margin-left: -0.625rem;
  width: 2.063rem;
  height: 2.063rem;
}
.back-text {
  font-size: 0.875rem;
  font-weight: bold;
}
.current-step {
  font-size: 0.875rem;
  font-weight: bold;
}
.total-step {
  font-size: 0.875rem;
  margin-left: 0.313rem;
}
.form-container {
  height: 100%;
}
.form-content {
  height: 100%;
}
</style>
