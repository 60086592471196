<template>
  <div>
    <v-row no-gutters>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <v-text-field
          class="form-input-right"
          v-model="deliveryZone.name"
          label="Nombre"
          :rules="[ isRequired ]"
          outlined
          dense
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <v-select
          class="form-input-left"
          v-model="deliveryZone.status"
          :items="statuses"
          item-text="name"
          item-value="key"
          label="Estado"
          :rules="[ isRequired ]"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <div class="cities-description">
      Seleccione las ciudades disponibles para entrega en la zona de envió
    </div>
    <v-text-field filled dense v-model="search"
      prepend-inner-icon="mdi-magnify"
      placeholder="Buscar"
      clearable
      hide-details />

    <div class="subheader-text">Ciudades</div>
    <div class="tree-container" v-if="regions">
      <v-treeview
        class="regions-tree"
        v-model="deliveryZone.cities"
        :items="regions"
        item-key="code"
        item-children="cities"
        :search="search"
        selectable
        return-object
        dense>
      </v-treeview>
    </div>
  </div>
</template>
<script>
import { isRequired } from '@/shared/validations'
import { mapState, mapActions } from 'vuex'
import { STATUSES } from '@/shared/statuses'

export default {
  props: {
    deliveryZone: {
      type: Object,
      default: function () {
        return {
          name: null,
          status: 'active',
          cities: []
        }
      }
    }
  },
  data () {
    return {
      statuses: STATUSES,
      search: null
    }
  },
  computed: {
    ...mapState('delivery', ['regions'])
  },
  methods: {
    isRequired,
    ...mapActions('delivery', ['getRegions'])
  },
  mounted () {
    this.getRegions(true)
  }
}
</script>
<style scoped>
.form-input-left {
  padding-left: 0.5rem;
}
.form-input-right {
  padding-right: 0.5rem;
}
.cities-description {
  font-size: 0.875rem;
  margin-bottom: 0.875rem;
}
.subheader-text {
  font-size: 0.813rem;
  color: #00000099;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-left: 0.625rem;
}
.regions-tree >>> .v-treeview-node__label {
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1rem;
}
.regions-tree >>> .v-icon.v-icon {
  font-size: 1.25rem;
}
.regions-tree >>> .v-treeview-node__root {
  min-height: 2.125rem;
}
.tree-container {
  max-height: 19.375rem;
  margin-top: 0.5rem;
  overflow-y: scroll;
}
@media only screen and (max-width: 37.5rem) {
  .form-input-left {
    padding-left: 0rem;
  }
  .form-input-right {
    padding-right: 0rem;
  }
  .tree-container {
    max-height: 100%;
    overflow-y: scroll;
  }
}
</style>
