export const DELIVERY_TYPE_STANDARD_DELIVERY = 'standard-delivery'
export const DELIVERY_TYPE_CASH_ON_DELIVERY = 'cash-on-delivery'
export const DELIVERY_TYPE_COLLECT_IN_STORE = 'collect-in-store'

export const DELIVERY_TYPES = [
  {
    key: DELIVERY_TYPE_STANDARD_DELIVERY,
    name: 'Envió normal'
  },
  {
    key: DELIVERY_TYPE_CASH_ON_DELIVERY,
    name: 'Pago contra entrega'
  },
  {
    key: DELIVERY_TYPE_COLLECT_IN_STORE,
    name: 'Recoger en la tienda'
  }
]

const DELIVERY_TYPES_DESCRIPTION = {
  [DELIVERY_TYPE_STANDARD_DELIVERY]: 'Envió a la dirección del comprador',
  [DELIVERY_TYPE_CASH_ON_DELIVERY]: 'Envió a la dirección del comprador',
  [DELIVERY_TYPE_COLLECT_IN_STORE]: 'El paquete será recogido en la tienda'
}

export function getDeliveryTypeDescription (deliveryType) {
  return DELIVERY_TYPES_DESCRIPTION[deliveryType] ? DELIVERY_TYPES_DESCRIPTION[deliveryType] : deliveryType
}
