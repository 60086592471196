<template>
  <form-loader v-if="loading && useLoading" />
  <v-row class="form-row-container" no-gutters v-else>
    <v-col xl="8" lg="9" md="11" sm="11" cols="12">
      <v-card :class="{ 'card-wrapper mx-auto': $vuetify.breakpoint.smAndUp }" elevation="2" tile>
        <v-form class="form-container" ref="form">
          <flex-view-layout class="flex-layout-container">
            <template v-slot:content>
              <slot name="header"></slot>
              <div class="form-content">
                <div class="form-title">
                  {{ title }}
                </div>
                <div class="form-description" v-if="description">
                  {{ description }}
                </div>
                <div class="row-container">
                  <slot name="content"></slot>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <slot name="footer">
                <v-btn class="text-none btn-default" depressed large :loading="loading" @click="submit">
                  {{ buttonText }}
                </v-btn>
              </slot>
            </template>
          </flex-view-layout>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import FlexViewLayout from '@/layouts/FlexViewLayout'
import FormLoader from '@/components/Loader/FormLoader'

export default {
  components: {
    FlexViewLayout,
    FormLoader
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String
    },
    useLoading: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      default: 'Crear'
    }
  },
  data () {
    return {
      config: null,
      form: {
        rows: []
      }
    }
  },
  computed: {
    ...mapState('shared', ['loading'])
  },
  methods: {
    submit () {
      if (!this.$refs.form.validate()) {
        return
      }

      this.$emit('submit')
    }
  }
}
</script>
<style scoped>
.form-row-container {
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.card-wrapper {
  min-height: 38.75rem;
}
.form-container {
  min-height: inherit;
}
.flex-layout-container {
  min-height: inherit;
  padding: 2.125rem 2.75rem;
}
.form-content {
  text-align: left;
  padding-bottom: 2rem;
  color: var(--app-text-color);
}
.form-title {
  font-size: 1rem;
  font-weight: 600;
}
.form-description {
  margin-top: 0.875rem;
  font-size: 0.875rem;
}
.row-container {
  padding-top: 1.5rem;
}

@media only screen and (max-width: 37.5rem) {
  .form-row-container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .card-wrapper {
    min-height: inherit;
  }
  .flex-layout-container {
    padding: 1rem 1.25rem 0.25rem 1.25rem;
  }
}
</style>
